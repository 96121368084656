var $ = require('jquery');
require('intl-tel-input/build/css/intlTelInput.css');
var intlTelInput = require('intl-tel-input/build/js/intlTelInput-jquery.js');
var intlTelInputUtils = require('intl-tel-input/build/js/utils.js');
var datepicker = require('air-datepicker/dist/js/datepicker.min.js')
var autocomplete = require('./jquery.autocomplete.js');
// var formActionURL = 'https://dissertatsija.com/account/';
var formActionURL = 'https://dissertatsija.com/wp-content/themes/dissertacija.local/addClientOrderHandler.php';

window.onload = function() {
    var button  = document.querySelectorAll('[data-reachgoal]');
    button.forEach(function(elem) {
        elem.addEventListener('click', function() {
            var target = elem.getAttribute('data-reachgoal');
            // ym(62429068, 'reachGoal', target);
        });
    });
}

var viewport = function(media) {

    var resolution = false,
        x = window.innerWidth;

    var resizeEnd;
    $(window).on('resize', function() {
        clearTimeout(resizeEnd);
        resizeEnd = setTimeout(function() {
            $(window).trigger('resize-end');
        }, 100);
    });

    if (x >= 1920) {
        if (media == "hd")
            resolution = true;
    } else if (x >= 1280 && x < 1920) {
        if (media == "wide")
            resolution = true;
    } else if (x >= 1024 && x < 1280) {
        if (media == "desktop")
            resolution = true;
    } else if (x >= 768 && x < 1024) {
        if (media == "tablet")
            resolution = true;
    } else if (x < 768) {
        if (media == "mobile")
            resolution = true;
    }

    if (x >= 1024) {
        if (media == "default")
            resolution = true;
    } else if (x < 1024) {
        if (media == "portable")
            resolution = true;
    }

    $(window).on('resize-end', function() {
        return resolution;
    });

    return resolution;

};

(function($){
    $(document).ready(function() {

        var cookie ={};

        function getCookie(name) {
            var matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }

        function setCookie(name, value, options) {
            options = options || {};

            var expires = options.expires;

            if (typeof expires == "number" && expires) {
                var d = new Date();
                d.setTime(d.getTime() + expires * 1000);
                expires = options.expires = d;
            }

            if (expires && expires.toUTCString) {
                options.expires = expires.toUTCString();
            }

            value = encodeURIComponent(value);

            var updatedCookie = name + "=" + value;

            for (var propName in options) {
                updatedCookie += "; " + propName;
                var propValue = options[propName];
                if (propValue !== true) {
                    updatedCookie += "=" + propValue;
                }
            }

            document.cookie = updatedCookie;
        }

        function getUrlVar(key) {
            var result = new RegExp(key + "=([^&]*)", "i").exec(window.location.search);
            return result && unescape(result[1]) || "";
        }

        var utm_source = getUrlVar("utm_source"),
            utm_medium = getUrlVar("utm_medium"),
            utm_campaign = getUrlVar("utm_campaign"),
            utm_term = getUrlVar("utm_term"),
            utm_content = getUrlVar("utm_content"),
            str_utm_source = "",
            str_utm_medium = "",
            str_utm_campaign = "",
            str_utm_term = "",
            str_utm_content = "",
            utm;

        if (utm_source) {
            str_utm_source = "utm_source=" + utm_source;

            // явное определение партнера отдаем на бэкенд, см. отправку заказа
            // if (utm_source == 'advertise' || utm_source == '7offers' || utm_source == 'cityads') {

            //     var uids = ['uid', 'tid', 'click_id'],
            //         webmaster_uid_value = getUrlVar('uid') || getUrlVar('tid') || getUrlVar('click_id');

            //     for (var i = 0; i < uids.length; i++) {
            //         if (webmaster_uid_value == getUrlVar(uids[i])) {
            //             current_uid = uids[i];
            //         }
            //     }

            //     var cookie_options = {
            //         expires: 1209600 // 14 days
            //     };

            //     setCookie('webmaster_source', utm_source, cookie_options);
            //     setCookie('webmaster_uid', current_uid, cookie_options);
            //     setCookie('webmaster_uid_value', webmaster_uid_value, cookie_options);
            // }
        }

        if (utm_medium)
            str_utm_medium = "&utm_medium=" + utm_medium;

        if (utm_campaign)
            str_utm_campaign = "&utm_campaign=" + utm_campaign;

        if (utm_term)
            str_utm_term = "&utm_term=" + utm_term;

        if (utm_content)
            str_utm_content = "&utm_content=" + utm_content;

        utm = str_utm_source + str_utm_medium + str_utm_campaign + str_utm_term + str_utm_content;


        function getReferrer() {
            var url = document.createElement('a');
            url.href = document.referrer;
            return url.hostname;
        }

        var auth = false;

        var TOKEN = getCookie('token');
        if (TOKEN) auth = true;

        // var destination = 'http://zaochnik.com.local';
        var destination = 'https://zaochnik1.com';

        var carouselOptions = {
            stagePadding: 15,
            margin: 30,
            nav: true,
            dots: true,
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                768: {
                    items: 2,
                    nav: false
                },
                1024: {
                    items: 3
                }
            }
        };

        modifyDate = function(date) {
            date = new Date(date);
            var month = date.getMonth() + 1;
            var MM = month < 10 ? '0' + month : month;
            var D = date.getDate();
            var YYYY = date.getFullYear();
            return D + '.' + MM + '.' + YYYY;
        }

        var carousel = $('[data-carousel = ""]'),
            carousel_reviews = $('[data-reviews = ""]');

        if (carousel_reviews.length) {
            $.get(destination + '/rest/v2/reviews/').done(function(data) {

                var responses = data.results.slice(0,10).map(function(item) {
                    return {
                        name: item.client_name,
                        text: item.message,
                        date: modifyDate(item.created)
                    };
                });

                for (var i = 0; i < responses.length; i++) {
                    var response = responses[i];
                    var template = '<div class="review"><div class="review__head"><span class="review__name">' + response.name + '</span><span class="review__date">' + response.date + '</span></div><div class="review__body">' + response.text + '</div></div>';
                    carousel_reviews.append(template);
                }

                carousel_reviews.owlCarousel(carouselOptions);

            }).fail(function() {
                carousel_reviews.append('Произошла ошибка. Отзывы не были загружены')
            });
        } else {
            carousel.owlCarousel(carouselOptions);
        }

        // $('.popular__list.owl-carousel').owlCarousel({
        //     stagePadding: 15,
        //     margin: 30,
        //     nav: true,
        //     dots: true,
        //     responsive: {
        //         0: {
        //             items: 1,
        //             nav: false
        //         },
        //         475: {
        //             items: 2,
        //             nav: false
        //         },
        //         768: {
        //             items: 3,
        //             nav: false
        //         },
        //         1024: {
        //             items: 4
        //         }
        //     }
        // })

        //Список предметов
        // $.get(destination + '/api/get_subjects/').done(function(data) {
        //     var options = {
        //         lookup: data,
        //         minChars: 0,
        //         onSelect: function(suggestion) {
        //             $('.js-subject').parent().addClass('success');
        //         }
        //     };
        //     $('.js-subject').autocomplete(options);
        // });

        var worktypes = [];
        var getWorkTypeTitle = function (id) {
            for (var i = 0; i < worktypes.length; i++) {
                if (worktypes[i].id == id) {
                    return worktypes[i].title;
                }
            }
        };

        //Список видов работ
        var receivedWorktypes = [];
        var worktypes = $.get(destination + '/rest/worktypes/');
        var pages = 10;

        worktypes.done(function(data) {

            worktypes = data.results;
            receivedWorktypes = data.results;

            var options = {
                lookup: data.results.map(function(item) {
                    return item.title.toString();
                }),
                minChars: 0,
                onSelect: function(suggestion) {
                    $('.js-worktype').parent().removeClass(classError).addClass('success');
                    $('.js-validate-WorkType').removeClass(classActive);
                    receivedWorktypes.forEach(function(item) {
                        if (item.title == suggestion.value) {
                            $('[name = WorkType]').val(item.id);
                            pages = item.pages;
                            if (pages === 0)
                                pages = 10;
                            $('[name = pagesAll]').val(pages);
                            if (item.id == 1 || item.id == 2 || item.id == 3 || item.id == 63) { //Диссертация, Дипломная или Курсовая
                                $('.js-shortdescr').addClass(classActive);
                                $('.js-shortdescr span').show();
                                $('.js-shortdescr input').addClass('js-required');
                            } else {
                                $('.js-shortdescr').removeClass(classActive);
                                $('.js-shortdescr span').hide();
                                $('.js-shortdescr input').removeClass('js-required');
                            };
                        }
                    });
                },
                onInvalidateSelection: function() {
                    $('.js-worktype').parent().removeClass(classError).removeClass('success').removeClass('success-alt');
                    $('[name = WorkType]').val(0);
                }
            };

            $('.js-worktype').autocomplete(options);

            $('.js-select').click(function() {
                $(this).next().focus();
            });

        });

        var validPhone = function(data) {
                reg = /\D/g;
                var num = data.replace(reg, "");
                return num;
            },
            phoneInput = $('[type = tel]'),
            phoneOptions = {
                preferredCountries: ['ru', 'ua', 'by', 'kz']
            };

        phoneInput.intlTelInput(phoneOptions);

        // if (viewport('default')) {
        //
        //     $('.js-datepicker').attr('type', 'text')
        //
        //     var datepickerOptions = {
        //         minDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        //         autoClose: true
        //     };
        //
        //     var datepicker = $('.js-datepicker').datepicker(datepickerOptions);
        //
        //     $('.js-datepicker-icon').click(function() {
        //         datepicker.show();
        //     });
        //
        // }

        var datepickerOptions = {
            minDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
            autoClose: true
        };

        var datepicker = $('.js-datepicker').datepicker(datepickerOptions);

        $('.js-datepicker-icon').click(function() {
            $('.js-datepicker').focus();
        });

        var classError = 'error',
            classActive = 'active',
            filesList = [],
            phoneNumber;

        function validateForm(event) {

            var error = false;

            $('[name = "full-form"] .js-required').each(function() {

                var element = $(this),
                    parent = $(this).closest(".field"),
                    elementName = $(this).attr("name");

                if (elementName == "WorkType") {
                    if (element.val() == 0) {
                        error = true;
                        parent.addClass(classError);
                        $('.js-validate-' + elementName).addClass(classActive);
                    }
                }

                if (elementName == "Subject") {
                    if (element.val() == "") {
                        error = true;
                        parent.addClass(classError);
                        $('.js-validate-' + elementName).addClass(classActive);
                    }
                }

                if (elementName == "ShortDescr") {
                    if (element.val() == "") {
                        error = true;
                        parent.addClass(classError);
                        $('.js-validate-' + elementName).addClass(classActive);
                    }
                }

                if (elementName == "pagesAll") {
                    if (!checknum(element.val()) || element.val() < 1) {
                        error = true;
                        parent.addClass(classError);
                        $('.js-validate-' + elementName).addClass(classActive);
                    }
                }

                if (elementName == "date") {
                    if (element.val() == "") {
                        error = true;
                        parent.addClass(classError);
                        $('.js-validate-' + elementName).addClass(classActive);
                    }
                }

                if (elementName == "FIO") {
                    if (!auth && element.val() == "") {
                        error = true;
                        parent.addClass(classError);
                        $('.js-validate-' + elementName).addClass(classActive);
                    }
                }

                if (elementName == "MobPhone") {
                    if (!auth && !element.intlTelInput("isValidNumber")) {
                        error = true;
                        parent.addClass(classError);
                        $('.js-validate-' + elementName).addClass('success');
                    } else {
                        phoneNumber = element.intlTelInput("getNumber");
                    }
                }

                if (elementName == "Email") {
                    if (!auth && !checkmail(element.val())) {
                        error = true;
                        parent.addClass(classError);
                        $('.js-validate-' + elementName).addClass(classActive);
                    }
                }

            });

            if (error == true) {
                $('.js-validate').show();
                return false;
            } else {
                return true;
            };

        }

        $('[name = "full-form"]').submit(function(event) {
            event.preventDefault();

            if (validateForm()) {

                var
                    worktype = $('[name = WorkTypeAuto]', this).val(),
                    subject = $('[name = Subject]', this).val(),
                    pagesAllnone = document.querySelector('[name=pagesAllnone]').value,
                    pages = $('[name = pagesAll]', this).val(),
                    date = $('[name = date]', this).val(),
                    description = $('[name = ShortDescr]', this).val(),
                    fio = $('[name = FIO]', this).val(),
                    email = $('[name = Email]', this).val(),
                    promo = $('[name = discount_code]', this).val(),
                    phone = phoneNumber;

                // var inverse_date = date.split(".").reverse().join("-");

                var data_order = {
                    "worktype": worktype,
                    "subjectText": subject,
                    "pagesAllnone": pagesAllnone,
                    "pagesAll": pages,
                    "date": date,
                    "description": description,
                    "promoCode": promo,
                    "utm": utm,
                    "ref": getReferrer()
                };

                var data_client = {
                    "fio": fio,
                    "phone": phone,
                    "email": email,
                    "utm": utm,
                    "ref": getReferrer()
                }

                var data_order_client = {
                    "WorkType": worktype,
                    "Subject": subject,
                    "pagesAll": pages,
                    "pagesAllnone": pagesAllnone ,
                    "date": date,
                    "ShortDescr": description,
                    "utm": utm,
                    "ref": getReferrer(),
                    "FIO": fio,
                    "MobPhone": phone,
                    "Email": email
                }

                // rest валидатор ругается, если отправляем пустое поле
                // поэтому добавляем его, если оно заполнено
                if (promo) {
                    data_order_client.promo_code = promo;
                }

                var ajaxError = function() {
                    openModal('callback-error');
                    $('.js-button').prop("disabled", false).removeClass('loading');
                }

                var ajaxSuccess = function(template, orderId) {
                    $('.js-button').prop("disabled", false).removeClass('loading');
                    $('[name]').each(function() {
                        $(this).closest(".field").removeClass('success').removeClass('success-alt');
                    });
                    $('.js-form')[0].reset();
                    openModal('order-' + template);
                    gtag('event', 'Submission',
                        {
                            'event_category' : 'Form',
                            'event_label' : 'Zaochnik2'
                        }
                    );
                    // ym(62429068, 'reachGoal', 'Form_Submission');
                }

                $('.js-button').prop("disabled", true).addClass('loading');

                var getAllGetParams = window.location.search.substr(1),
                   rest_v2_orders = (getAllGetParams) ? destination + "/rest/v2/orders/?" + getAllGetParams : destination + "/rest/v2/orders/";

                // На rest_v2_orders отправляем любые параметры
                // var webmaster_source = getCookie('webmaster_source'),
                // webmaster_uid = getCookie('webmaster_uid'),
                // webmaster_uid_value = getCookie('webmaster_uid_value');

                // if (webmaster_source && webmaster_uid && webmaster_uid_value) {
                //     rest_v2_orders += "?utm_source=" + webmaster_source + "&" + webmaster_uid + "=" + webmaster_uid_value;
                // }

                var order = $.ajax({
                    url: (getAllGetParams) ? `${formActionURL}/?${getAllGetParams}` : formActionURL,
                    type: "POST",
                    data: data_order_client,
                    // contentType: "application/json",
                    contentType: "application/x-www-form-urlencoded",
                    dataType: "json"
                });

                order.then(function (res) {
                    // console.log('Response:', res);
                    document.querySelector('.popup-box_success').children[0].innerText = `${res.message}`;
                    document.querySelector('.js-order-success-link').addEventListener('click', function(){
                        document.location='https://dissertacija.biz/account/';
                    });
                    if (res.info) {
                        document.querySelector('.js-order-success-link').style.display = 'none';
                    }
                    ajaxSuccess('success');
                    // $('.js-order-success-link').attr('href', res.link);
                    // setTimeout(function (){
                    //     window.location.href = res.link;
                    // }, 2000);
                }, function (res) {
                    if (res.responseJSON.type == 'fake_order') {
                        ajaxSuccess('exist');
                    } else {
                        ajaxError();
                    }
                });
            }
        });

        $(document).on({
            blur: function () {

                var element = $(this),
                    parent = $(this).closest(".field"),
                    elementName = $(this).attr("name");

                parent.removeClass('success').removeClass('success-alt');

                if (elementName == "WorkTypeAuto") {
                    const workTypeValue = document.querySelector('input[name = "WorkType"]');
                    setTimeout(() => {
                        if (workTypeValue.value > 0) {
                            parent.find('span').css('display', 'none');
                        } else {
                            parent.find('span').css('display', 'block');
                        }
                    }, 100);
                }

                if (elementName == "Subject") {
                    if (element.val() != "") {
                        parent.addClass('success');
                    }
                }

                if (elementName == "pagesAll") {
                    if (checknum(element.val()) && element.val() >= 1) {
                        parent.addClass('success');
                    }
                }

                if (elementName == "ShortDescr") {
                    if (element.val() != "") {
                        parent.addClass('success');
                    }
                }

                if (elementName == "date") {
                    if (element.val() != "") {
                        parent.addClass('success-alt');
                        parent.find('span').css('display', 'none');
                    }
                }

                if (elementName == "FIO") {
                    if (element.val() != "") {
                        parent.addClass('success');
                    }
                }

                if (elementName == "MobPhone") {
                    if (element.intlTelInput("isValidNumber")) {
                        parent.addClass('success');
                    }
                }

                if (elementName == "Email") {
                    if (element.val() != "" && checkmail(element.val())) {
                        parent.addClass('success');
                    }
                }
            },
            focus: function() {
                var elementName = $(this).attr('name');
                var parent = $(this).closest(".field");
                parent.removeClass(classError);
                $('.js-validate-' + elementName).removeClass(classActive);
                if ($('.alert_item.active').length == 0) {
                    $('.js-validate').hide();
                }

                if (elementName == "WorkTypeAuto") {
                    const workTypeValue = document.querySelector('input[name = "WorkType"]');
                    setTimeout(() => {
                        if (workTypeValue.value > 0) {
                            parent.find('span').css('display', 'none');
                        } else {
                            parent.find('span').css('display', 'block');
                        }
                    }, 100);
                }
            },
            change: function () {
                var elementName = $(this).attr('name');
                var parent = $(this).closest(".field");
                if (elementName == "WorkTypeAuto") {
                    const workTypeValue = document.querySelector('input[name = "WorkType"]');
                    setTimeout(() => {
                        if (workTypeValue.value > 0) {
                            parent.find('span').css('display', 'none');
                        } else {
                            parent.find('span').css('display', 'block');
                        }
                    }, 100);
                }
            }
        }, '[name]');

        function openModal(modal) {
            var thisModal = modal;
            $(".js-modal-layer").addClass("active");
            $('.js-modal').each(function() {
                $(this).hide();
                if ($(this).data("modal") == thisModal) {
                    $(this).show().animate({
                        opacity: 1
                    }, 600);
                }
            });
        }

        function closeModal() {
            $('.js-modal').each(function() {
                $(this).animate({
                    opacity: 0
                }, 600, function() {
                    $(this).hide();
                    $(".js-modal-layer").removeClass("active");
                });
            });
        }

        $('.js-modal-open').click(function(event) {
            event.preventDefault();
            var thisModal = $(this).data("modal");
            openModal(thisModal);
        });

        $('.js-modal-close').click(function(event) {
            event.preventDefault();
            closeModal();
        });

        $('.js-modal-layer').click(function(event) {
            event.preventDefault();
            var modal = $('.js-modal');
            if (!modal.is(event.target) && modal.has(event.target).length === 0) {
                closeModal();
            }
        });

/*         $('.js-callback').submit(function(event) {

            event.preventDefault();

            var phoneNum = $(".js-callback-phone"),
                parent = $(".js-callback-phone").parent();

            if (phoneNum.intlTelInput("isValidNumber")) {
                var phone = {
                    "phone": validPhone(phoneNum.intlTelInput("getNumber"))
                };
                $.ajax({
                    type: "POST",
                    url: destination + "/rest/client/callback/",
                    data: JSON.stringify(phone),
                    contentType: "application/json",
                    dataType: "json",
                    success: function(msg) {
                        openModal('callback-success');
                        parent.removeClass('success');
                        $('.js-callback')[0].reset();
                        ym(51459284, 'reachGoal', 'Callback_Submission');
                    },
                    error: function() {
                        parent.removeClass('success');
                        parent.removeClass('success-alt');
                        $('.js-callback')[0].reset();
                        openModal('callback-error');
                    }
                });
            } else {
                parent.addClass(classError);
            }
        });

        $(".js-callback-phone").focusout(function() {
            $(this).parent().removeClass(classError);
        }); */

        /* PopUp Valentine's day */
        // if(!localStorage.getItem('valentines')) {
        //     setTimeout(function() {
        //         openModal('valentines-day');
        //     }, 10000);
        // }

        // $('.popup-val__button').on('click', function() {
        //     $('[name="discount_code"]').val('love');
        //     localStorage.setItem('valentines', true);
        //     closeModal();
        // });

        /* PopUp Womens's day */
        // if(!localStorage.getItem('womensday')) {
        //     setTimeout(function() {
        //         openModal('womensday-day');
        //     }, 10000);
        // }

        // $('[data-action-button]').on('click', function() {
        //     $('[name="discount_code"]').val('8mart');
        //     localStorage.setItem('womensday', true);
        //     closeModal();
        // });
    });
})(jQuery);

checknum = function(value) {
    reg = /^[+]{0,1}[0-9]*$/;
    if (!value.match(reg)) {
        return false;
    } else {
        return true;
    }
}

checkmail = function(value) {
    reg = /^((([a-z]|[0-9]|!|#|$|%|&|'|\*|\+|\-|\/|=|\?|\^|_|`|\{|\||\}|~)+(\.([a-z]|[0-9]|!|#|$|%|&|'|\*|\+|\-|\/|=|\?|\^|_|`|\{|\||\}|\.|~)+)*)@((((([a-z]|[0-9])([a-z]|[0-9]|\-){0,61}([a-z]|[0-9])\.))*([a-z]|[0-9])([a-z]|[0-9]|\-){0,61}([a-z]|[0-9])\.)[\w]{2,4}|(((([0-9]){1,3}\.){3}([0-9]){1,3}))|(\[((([0-9]){1,3}\.){3}([0-9]){1,3})\])))$/i;
    if (!value.match(reg)) {
        return false;
    } else {
        return true;
    }
}

$('<div class="quantity-nav"><div class="quantity-button quantity-up"></div><div class="quantity-button quantity-down"></div></div>').insertAfter('.quantity input');
$('.quantity').each(function() {
    var spinner = jQuery(this),
        input = spinner.find('input[type="number"]'),
        btnUp = spinner.find('.quantity-up'),
        btnDown = spinner.find('.quantity-down'),
        min = input.attr('min'),
        max = input.attr('max');

    btnUp.click(function() {
        var oldValue;
        if (input.val() == '') {
            oldValue = 0;
        } else {
            oldValue = parseFloat(input.val());
        }
        if (oldValue >= max) {
            var newVal = oldValue;
        } else {
            var newVal = oldValue + 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
    });

    btnDown.click(function() {
        var oldValue;
        if (input.val() == '') {
            oldValue = 0;
        } else {
            oldValue = parseFloat(input.val());
        }
        if (oldValue <= min) {
            var newVal = oldValue;
        } else {
            var newVal = oldValue - 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
    });
});
