import $ from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

$(document).ready(function () {
    const owl = $('.carousel');
    owl.owlCarousel(    
        {   
            items: 4,
            // margin: 12,
            nav: true,
            animateIn: true,
            autoplayTimeout: 1000,
            mouseDrag: true,
            touchDrag: true,
            pullDrag: true,
            loop: true,
            responsiveClass: true,
            responsive: {
                320:{
                    items: 1.5,
                    center: true,
                    margin: 10,
                },
                640:{
                    items:2,
                },
                768:{
                    items: 3,
                    margin: 5,
                },
                1024:{
                    items: 3,
                    margin: 20
                },
                1440:{
                    items:4,
                }
            }
        });
    $('.nav-buttons_item.next').click(function () {
        owl.trigger('next.owl.carousel');
    });
    $('.nav-buttons_item.prev').click(function () {
        owl.trigger('prev.owl.carousel', [300]);
    });
});
  