import $ from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

$(document).ready(() => {
  const owl = $('.carousel-2');
  owl.owlCarousel(
    {
      items: 3,
      margin: 12,
      nav: true,
      animateIn: true,
      autoplayTimeout: 1000,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      responsiveClass: true,
      loop: true,
      responsive: {
        0: {
          items: 1,
        },
        640: {
          items: 2,
        },
        768: {
          items: 2,
        },
        1140: {
          items: 3,
        },
        1440: {
          items: 3,
        },
      },
    },
  );
  $('.nav-buttons_item.owl-next').click(() => {
    owl.trigger('next.owl.carousel');
  });
  $('.nav-buttons_item.owl-prev').click(() => {
    owl.trigger('prev.owl.carousel', [300]);
  });
});
